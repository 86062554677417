'use client'

import { getSession, signIn } from 'next-auth/react'
import React, { useState } from 'react'
import { deleteCookie, getCookie, hasCookie } from 'cookies-next'
import { Session } from 'next-auth'
import { useRouter } from 'next/navigation'
import { useSearchParams } from 'next/navigation'
import { CALLBACK_LOGIN_URL, CALLBACK_SIGN_UP_URL } from '@scalis/components/team'
import { IS_SIGN_UP_FLOW } from '~/hooks/account/account.constants'
import { Card } from '~/scalis-components/core'
import { useNotification } from '~/scalis-components/core/notification'
import { Login, LoginBody } from '~/scalis-components/team/authentication/login'
import { Routes } from '~/src/app/_constants/routes'
import { JOB_LISTING_ID_TO_APPLY } from '~/src/app/application/application.constants'
import { useEmployerBranded } from '~/src/app/jobs/hooks/use-employer-branded'
import { notificationErrorHandler } from '~/utils/error'
import logger from '~/utils/logger'

export const LoginPage: React.FC = () => {
  const { notification } = useNotification()
  const isSignUpFlow = hasCookie(IS_SIGN_UP_FLOW)
  const searchParams = useSearchParams()
  const [authError, setAuthError] = useState<string | null>(null)

  const router = useRouter()

  const pushUserAfterLogin = (session: Session) => {
    if (session.user.emailVerified) {
      if (isSignUpFlow) {
        const jobListingId = getCookie(JOB_LISTING_ID_TO_APPLY)
        if (jobListingId) {
          deleteCookie(JOB_LISTING_ID_TO_APPLY)
          return router.push(`${CALLBACK_SIGN_UP_URL}?jobListingId=${jobListingId}`)
        }

        return router.push(`${CALLBACK_SIGN_UP_URL}`)
      }

      checkUserWorkspace(session.user)
    } else if (!session.user.emailVerified) {
      const jobListingId = getCookie(JOB_LISTING_ID_TO_APPLY)
      router.replace(
        `/email-validation?userId=${session.user.id}&email=${session.user.email}${
          jobListingId ? `&jobListingId=${jobListingId}` : ''
        }`,
      )
    }
  }

  const checkUserWorkspace = async (user: any) => {
    try {
      const hasAvailableWorkspaces = !!user?.workspace.companies.length
      const hasOnlyOneWorkspace = user?.workspace.companies.length === 1
      const callbackUrl = searchParams?.get('callbackUrl')

      const accountSelectorUrl = new URL(Routes.auth.account_selector, window.location.origin)
      const userProfileUrl = new URL(Routes.user.profile, window.location.origin)

      const appendQueryParam = (url: URL, key: string, value: string | undefined | null) => {
        if (value) url.searchParams.append(key, value)
      }

      if (callbackUrl) {
        return router.push(callbackUrl)
      }

      if (hasAvailableWorkspaces && hasOnlyOneWorkspace) {
        appendQueryParam(accountSelectorUrl, 'workspaceId', user?.workspace.companies[0]?.id)
        return router.push(accountSelectorUrl.toString())
      }

      if (hasAvailableWorkspaces && !hasOnlyOneWorkspace) {
        appendQueryParam(accountSelectorUrl, 'showWorkspacesList', 'true')
        return router.push(accountSelectorUrl.toString())
      }

      if (!hasAvailableWorkspaces) {
        return router.push(userProfileUrl.toString())
      }

      return router.push(userProfileUrl.toString())
    } catch (error) {
      logger.error(error)
      notificationErrorHandler({
        notification,
        responseError: error,
      })
    }
  }

  const handleSignIn: LoginBody = async ({ email, password }) => {
    try {
      setAuthError(null)
      const response = await signIn('credentials', {
        email,
        password,
        redirect: false,
      })

      getSession().then((session) => {
        if (session) pushUserAfterLogin(session)
      })

      if (response?.error) {
        setAuthError(response.error)
      }
    } catch (error) {
      logger.info('SIGN IN ERROR', error)
      notificationErrorHandler({
        notification,
        responseError: error,
      })
    }
  }

  const { hasCompanyData, hasJobListingToApply, companyName, jobListingTitle, callbackUrl } = useEmployerBranded()

  return (
    <div className='mx-4 flex w-full flex-col items-center'>
      {hasCompanyData && hasJobListingToApply && callbackUrl?.startsWith(Routes.application_flow.profile) && (
        <p className='mb-2 text-sm text-neutral-secondary'>Application for {jobListingTitle}</p>
      )}
      <Card className='w-full max-w-[448px] rounded-xl bg-neutral-00 p-6 lg:p-10'>
        <Login
          onSubmit={handleSignIn}
          authError={authError}
          companyName={hasCompanyData ? companyName : undefined}
        />
      </Card>
    </div>
  )
}
