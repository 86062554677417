import { useSearchParamsManager } from '~/scalis-components/team/hooks'
import { AvatarGroup, AvatarProps, AvatarVariantSizeEnum, Icon } from '~/scalis-components/core'
import { DIALOG_QUERY_PARAM } from '../candidate-collaborators.constants'
import { BUTTON_TITLE } from './trigger.constants'
import { CandidateCollaboratorsTriggerProps } from './trigger.types'

import { CANDIDATE_COLLABORATORS_TRIGGER_MOCK } from './trigger.mock'

export const CandidateCollaboratorsTrigger = ({
  candidateProfileId,
  shouldReplaceRoute = false,
}: CandidateCollaboratorsTriggerProps) => {
  const { updateQueryParam } = useSearchParamsManager()

  const avatars: AvatarProps[] = CANDIDATE_COLLABORATORS_TRIGGER_MOCK

  const onClick = () => {
    updateQueryParam(DIALOG_QUERY_PARAM, candidateProfileId.toString(), {
      replace: shouldReplaceRoute,
    })
  }

  return (
    <>
      {!avatars.length && (
        <button
          title={BUTTON_TITLE}
          onClick={onClick}
        >
          <Icon icon='fa-regular fa-circle-plus' />
        </button>
      )}

      {!!avatars.length && (
        <button
          title={BUTTON_TITLE}
          onClick={onClick}
        >
          <AvatarGroup
            size={AvatarVariantSizeEnum.Small}
            maxAvatars={5}
            avatars={avatars}
          />
        </button>
      )}
    </>
  )
}
