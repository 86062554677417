'use client'

import Link from 'next/link'
import React from 'react'
import logger from '~/utils/logger'

import { Button, ButtonWidth } from '@scalis/components/core/button'

import { Body as BodyText, BodySize as TextSize, Title } from '@scalis/components/core/typography'
import { useSearchParams } from 'next/navigation'
import useSourceJob from '~/hooks/jobseeker/useSourceJob'
import { toast } from '~/hooks/useToast'
import { errorHandler } from '~/utils/error'
import { LOGIN_MAP, LoginProps } from '.'
import { LoginForm, LoginFormValues } from './components/login-form'

export const Login: React.FC<LoginProps> = ({ onSubmit, authError, companyName, hideText }) => {
  const { storeSourceJob } = useSourceJob()
  const searchParams = useSearchParams()
  const callbackUrl = searchParams?.get('callbackUrl')
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)

  const handleSubmit = async (data: LoginFormValues) => {
    setIsSubmitting(true)
    try {
      storeSourceJob()

      await onSubmit?.(data)
    } catch (error) {
      errorHandler(toast, error)
      logger.error(error)
    }

    setIsSubmitting(false)
  }

  const onErrorSubmit = (error: any) => {
    logger.error(error)
    setIsSubmitting(false)
  }

  return (
    <div className='flex flex-col gap-6'>
      {!hideText && (
        <div className='flex w-full flex-col'>
          <Title center>{companyName ? LOGIN_MAP.brandedTitle(companyName) : LOGIN_MAP.title}</Title>
          <BodyText
            center
            size={TextSize.small}
          >
            {LOGIN_MAP.subtitle}
          </BodyText>
        </div>
      )}
      <LoginForm
        onSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        onErrorSubmit={onErrorSubmit}
        authError={authError}
      />
      <div className='flex flex-col gap-4'>
        <Button
          width={ButtonWidth.full}
          aria-label={LOGIN_MAP.labelSubmit}
          loading={isSubmitting}
          onClick={() => setIsSubmitting(true)}
        >
          {LOGIN_MAP.labelSubmit}
        </Button>
      </div>
      {!hideText && (
        <div className='flex flex-col gap-4'>
          <BodyText
            center
            size={TextSize.small}
          >
            {LOGIN_MAP.helperText}{' '}
            <Link
              href={LOGIN_MAP.getHelperLink(callbackUrl)}
              className='font-medium text-brand-primary-rest'
            >
              {LOGIN_MAP.helperLinkText}
            </Link>
          </BodyText>
        </div>
      )}
    </div>
  )
}
