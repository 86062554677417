import React from 'react'
import { Body, BodySize, BodyType } from '~/scalis-components/core/typography'

import { ApplicationStatusTag } from '../application-status'
import { StageStatusTag } from '../stage-status'
import { StageApplicationStatusTagProps } from './stage-application-status-tag.types'

export const StageApplicationStatusTag = ({
  milestoneType,
  stageName,
  applicationStatus,
  lastUpdatedStatusAt,
}: StageApplicationStatusTagProps) => (
  <StageStatusTag
    className='h-8 pr-1'
    milestoneType={milestoneType}
    stageName={stageName}
    small
    text={
      <div className='flex items-center gap-1'>
        {stageName && (
          <Body
            size={BodySize.small}
            type={BodyType.emphasys}
            className='max-w-[105px] truncate'
          >
            {stageName}
          </Body>
        )}
        {applicationStatus && (
          <ApplicationStatusTag
            applicationStatus={applicationStatus}
            lastUpdatedStatusAt={lastUpdatedStatusAt}
          />
        )}
      </div>
    }
  />
)
