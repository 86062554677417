import { VeteranStatusEnum } from '~/bff/graphql/generated/graphql'

// TODO after BE update the data update this mapper
export const veteranStatusMap: { [key in VeteranStatusEnum]: string } = {
  [VeteranStatusEnum.ArmedForcesServiceMedalVeteran]: 'Armed Forces Service Medal Veteran',
  [VeteranStatusEnum.DisabledVeteran]: 'Disabled Veteran',
  [VeteranStatusEnum.DoNotWishToAnswer]: 'Do Not Wish To Answer',
  [VeteranStatusEnum.NotAProtectedVeteran]: 'Not A Protected Veteran',
  [VeteranStatusEnum.OtherProtectedVeteran]: 'Other Protected Veteran',
  [VeteranStatusEnum.RecentlySeparatedVeteran]: 'Recently Separated Veteran',
}
