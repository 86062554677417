import { ApplicationStatusEnum } from '~/bff/graphql/generated/graphql'

export const applicationStatusMap: { [key in ApplicationStatusEnum]: string } = {
  [ApplicationStatusEnum.InterviewCompleted]: 'Interview Completed',
  [ApplicationStatusEnum.InterviewNotScheduled]: 'Interview Not Scheduled',
  [ApplicationStatusEnum.InterviewRequested]: 'Interview Requested',
  [ApplicationStatusEnum.InterviewResend]: 'Interview Resend',
  [ApplicationStatusEnum.InterviewScheduled]: 'Interview Scheduled',
  [ApplicationStatusEnum.InvitedToApply]: 'Invited to Apply',
  [ApplicationStatusEnum.InviteDeclined]: 'Invite Declined',
  [ApplicationStatusEnum.Prospect]: 'Prospect',
  [ApplicationStatusEnum.Rejected]: 'Rejected',
  [ApplicationStatusEnum.NeedsReview]: 'Needs Review',
  // [ApplicationStatusEnum.Hired]: "Hired",
}
