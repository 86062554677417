import React from 'react'
import { Tag } from '~/scalis-components/core'
import { Caption, CaptionType } from '~/scalis-components/core/typography'
import { cn } from '~/utils/cn'
import { applicationStatusMap } from '~/utils/enum-mappers'

import { ApplicationStatusTagProps } from './application-status-tag.types'
import { formatStatusTime, getApplicationStatusTagColor } from './application-status-tag.utils'

export const ApplicationStatusTag = ({
  applicationStatus,
  lastUpdatedStatusAt,
  ...props
}: ApplicationStatusTagProps) => (
  <Tag
    color={getApplicationStatusTagColor(applicationStatus)}
    text={
      <div className='flex font-normal'>
        <Caption
          type={CaptionType.emphasys}
          className={cn({
            'max-w-[105px] truncate': !!lastUpdatedStatusAt,
          })}
        >
          {applicationStatusMap[applicationStatus]}
        </Caption>
        {lastUpdatedStatusAt && (
          <Caption
            className='truncate px-0.5'
            italic
          >
            {formatStatusTime(lastUpdatedStatusAt)}
          </Caption>
        )}
      </div>
    }
    small
    {...props}
  />
)
