export const generateOptionsFromEnumMap = <T extends Record<string, string>>(enumObject: T) => {
  return Object.keys(enumObject).map((key) => ({
    value: key as keyof T,
    label: enumObject[key],
  })) as {
    value: keyof T
    label: string
  }[]
}

export const getEnumKeyByEnumValue = <T extends { [x: string]: any }>(myEnum: T, enumValue: string) =>
  Object.keys(myEnum).find((x) => myEnum[x] == enumValue) || enumValue
