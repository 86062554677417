export const AdsSize = {
  small: {
    width: 320,
    height: 50,
  },
  medium: {
    width: 300,
    height: 250,
  },
  large: {
    width: 728,
    height: 90,
  },
} as const

export interface AdvertisementProps {
  className?: string
  style?: object
  size?: (typeof AdsSize)[keyof typeof AdsSize]
}
