'use client'

import { Card } from '~/scalis-components/core'
import { cn } from '~/utils/cn'
import { AdsSize, AdvertisementProps } from './advertisement.types'

export const Advertisement = ({ className, style, size = AdsSize.medium }: AdvertisementProps) => {
  const { height, width } = size

  return (
    <Card
      className={cn(className, 'w-full items-center justify-center overflow-hidden p-2')}
      style={style}
      data-testid='advertisement'
    >
      <sellwild-ad
        height={height}
        width={width}
      />
    </Card>
  )
}
