import { EmployeeCountEnum } from '~/bff/graphql/generated/graphql'

export const employeeCountMap: { [key in EmployeeCountEnum]: string } = {
  [EmployeeCountEnum.Count_1To_3]: '1 to 3',
  [EmployeeCountEnum.Count_3To_5]: '3 to 5',
  [EmployeeCountEnum.Count_6To_10]: '6 to 10',
  [EmployeeCountEnum.Count_11To_20]: '11 to 20',
  [EmployeeCountEnum.Count_20To_100]: '20 to 100',
  [EmployeeCountEnum.Count_100To_500]: '100 to 500',
  [EmployeeCountEnum.Count_500Plus]: '500+',
}
