import { z } from 'zod'

export const loginSchema = z.object({
  email: z.string(),
  password: z.string(),
})

export type LoginBody = (credentials: z.input<typeof loginSchema>) => Promise<unknown>

export interface LoginProps {
  onSubmit?: LoginBody
  onContinueWithGoogle?: () => void
  authError?: string | null
  companyName?: string | null
  hideText?: boolean
}
