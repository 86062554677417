import resolveConfig from 'tailwindcss/resolveConfig'
import { NameEnum } from '~/bff/graphql/generated/graphql'
import tailwindConfig from '~/tailwind.config.design-system'

const fullConfig = resolveConfig(tailwindConfig)

export const getMilestoneStatusTagColor = (milestoneType?: NameEnum) => {
  switch (milestoneType) {
    case NameEnum.Sourcing:
      return 'bg-dataviz-violet-40'
    case NameEnum.ApplicationReview:
      return 'bg-dataviz-lilac-40'
    case NameEnum.PreScreen:
      return 'bg-dataviz-cyan-40'
    case NameEnum.Interviewing:
      return 'bg-dataviz-teal-40'
    case NameEnum.Offer:
      return 'bg-dataviz-lime-40'
    case NameEnum.Hired:
      return 'bg-dataviz-yellow-40'
    default:
      return ''
  }
}

// FullCalendar operates with hex colors, so we need to convert tailwind colors to hex
export const getMilestoneHexColor = (milestoneType?: NameEnum): string => {
  const dataviz = fullConfig.theme.backgroundColor.dataviz as any

  if (!dataviz) return ''

  try {
    switch (milestoneType) {
      case NameEnum.Sourcing:
        return dataviz.violet?.[40] ?? ''
      case NameEnum.ApplicationReview:
        return dataviz['lilac-blue']?.[40] ?? ''
      case NameEnum.PreScreen:
        return dataviz.cyan?.[40] ?? ''
      case NameEnum.Interviewing:
        return dataviz.teal?.[40] ?? ''
      case NameEnum.Offer:
        return dataviz.lime?.[40] ?? ''
      case NameEnum.Hired:
        return dataviz.yellow?.[40] ?? ''
      default:
        return ''
    }
  } catch (error) {
    console.error('Error getting milestone hex color:', error)
    return ''
  }
}
