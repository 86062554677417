import React from 'react'
import { cn } from '~/utils/cn'
import { Caption, CaptionType } from '~/scalis-components/core/typography'
import { StatusTag } from '~/scalis-components/core'
import { getMilestoneStatusTagColor } from './stage-status-tag.utils'
import { StageStatusTagProps } from './stage-status-tag.types'

export const StageStatusTag = ({ milestoneType, stageName, className, text, small }: StageStatusTagProps) => {
  return (
    <StatusTag
      className={cn('h-6', className)}
      contentContainerClassName='gap-[6px]'
      statusIconClassName={getMilestoneStatusTagColor(milestoneType)}
      small={small}
      text={
        <div className='flex items-center gap-[6px]'>
          {!text && stageName && <Caption type={CaptionType.emphasys}>{stageName}</Caption>}

          {text}
        </div>
      }
    />
  )
}
