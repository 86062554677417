export const CANDIDATE_COLLABORATORS_TRIGGER_MOCK = [
  {
    id: 1,
    fullName: 'John Doe',
    src: 'https://avatar.iran.liara.run/public/boy?username=1',
  },
  {
    id: 2,
    fullName: 'Jane Smith',
    src: 'https://avatar.iran.liara.run/public/girl?username=2',
  },
  {
    id: 3,
    fullName: 'Mike Johnson',
    src: 'https://avatar.iran.liara.run/public/boy?username=3',
  },
  {
    id: 4,
    fullName: 'Sarah Williams',
    src: 'https://avatar.iran.liara.run/public/girl?username=4',
  },
  {
    id: 5,
    fullName: 'James Brown',
    src: 'https://avatar.iran.liara.run/public/boy?username=5',
  },
  {
    id: 6,
    fullName: 'Emily Davis',
    src: 'https://avatar.iran.liara.run/public/girl?username=6',
  },
]
