import { IndustryEnum } from '~/bff/graphql/generated/graphql'

export const industryMap: { [key in IndustryEnum]: string } = {
  [IndustryEnum.AerospaceAndDefense]: 'Aerospace And Defense',
  [IndustryEnum.Agriculture]: 'Agriculture',
  [IndustryEnum.ArtsEntertainmentAndRecreation]: 'Arts Entertainment And Recreation',
  [IndustryEnum.Automotive]: 'Automotive',
  [IndustryEnum.Construction]: 'Construction',
  [IndustryEnum.ConsultingServices]: 'Consulting Services',
  [IndustryEnum.ECommerce]: 'E-Commerce',
  [IndustryEnum.Education]: 'Education',
  [IndustryEnum.EnergyMiningAndUtilities]: 'Energy Mining And Utilities',
  [IndustryEnum.EnvironmentalServices]: 'Environmental Services',
  [IndustryEnum.FashionAndApparel]: 'Fashion And Apparel',
  [IndustryEnum.FinancialServices]: 'Financial Services',
  [IndustryEnum.FoodAndBeverage]: 'Food And Beverage',
  [IndustryEnum.GovernmentAndPublicAdminstration]: 'Government And Public Adminstration',
  [IndustryEnum.Healthcare]: 'Healthcare',
  [IndustryEnum.HotelsAndTravelAccomodation]: 'Hotels And Travel Accomodation',
  [IndustryEnum.HumanResourcesAndStaffing]: 'Human Resources And Staffing',
  [IndustryEnum.InformationTechnology]: 'Information Technology',
  [IndustryEnum.Insurance]: 'Insurance',
  [IndustryEnum.Legal]: 'Legal',
  [IndustryEnum.ManagementAndConsulting]: 'Management And Consulting',
  [IndustryEnum.Manufacturing]: 'Manufacturing',
  [IndustryEnum.MediaAndCommunication]: 'Media And Communication',
  [IndustryEnum.NonprofitAndNgo]: 'Nonprofit And NGO',
  [IndustryEnum.PersonalConsumerServices]: 'Personal Consumer Services',
  [IndustryEnum.PharmaceuticalAndBiotech]: 'Pharmaceutical And Biotech',
  [IndustryEnum.ProfessionalServices]: 'Professional Services',
  [IndustryEnum.RealEstate]: 'Real Estate',
  [IndustryEnum.RestaurantsAndFoodService]: 'Restaurants And Food Service',
  [IndustryEnum.RetailAndWholesale]: 'Retail And Wholesale',
  [IndustryEnum.SocialMediaAndNetworking]: 'Social Media And Networking',
  [IndustryEnum.Telecommunications]: 'Telecommunications',
  [IndustryEnum.TransportationAndLogistics]: 'Transportation And Logistics',
}
