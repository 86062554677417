import { Tag } from '~/scalis-components/core'
import { Body, BodySize, BodyType } from '~/scalis-components/core/typography'
import { SkillTagProps } from './skill.types'

export const SkillTag = ({ name, level, yearsOfExp, ...props }: SkillTagProps) => {
  let text = ''
  if (level) {
    text += `・${level}`
  }
  if (yearsOfExp) {
    text += `・${yearsOfExp} year` + (yearsOfExp > 1 ? 's' : '')
  }

  return (
    <Tag
      {...props}
      textAsChild
      text={
        <div className='flex'>
          <Body type={BodyType.emphasys}>{name}</Body>
          <Body
            size={BodySize.small}
            italic
            className='text-neutral-secondary'
          >
            {text}
          </Body>
        </div>
      }
    />
  )
}
