import { JobTypeEnum } from '~/bff/graphql/generated/graphql'

export const jobTypeMap: Record<JobTypeEnum, string> = {
  [JobTypeEnum.Contractor]: 'Contractor',
  [JobTypeEnum.FullTime]: 'Full Time',
  [JobTypeEnum.Internship]: 'Internship',
  [JobTypeEnum.Other]: 'Other',
  [JobTypeEnum.PartTime]: 'Part Time',
  [JobTypeEnum.PerDiem]: 'Per Diem',
  [JobTypeEnum.Seasonal]: 'Seasonal',
  [JobTypeEnum.Temporary]: 'Temporary',
}

export const jobTypeOptions = Object.values(JobTypeEnum).map((value) => ({
  label: jobTypeMap[value],
  value,
}))
