import { getCookie } from 'cookies-next'
import { useSearchParams } from 'next/navigation'
import { useEffect, useState } from 'react'
import { useGetCompanyWithSubdomainQuery, useGetPublicJobListingQuery } from '~/bff/graphql/generated/graphql'
import { JOB_LISTING_ID_TO_APPLY } from '../../application/application.constants'
import useClientSubdomain from '~/hooks/use-client-subdomain'

export const useEmployerBranded = () => {
  const subdomain = useClientSubdomain()

  const searchParams = useSearchParams()
  const callbackUrl = searchParams?.get('callbackUrl')

  const { data: companyData } = useGetCompanyWithSubdomainQuery({
    skip: !subdomain,
    variables: {
      subdomain,
    },
  })

  const company = companyData?.GetCompanyInfo?.[0]

  const jobListingId = getCookie(JOB_LISTING_ID_TO_APPLY)

  const { data: jobListingData } = useGetPublicJobListingQuery({
    skip: !jobListingId,
    variables: {
      publicListingId: jobListingId as string,
    },
  })

  const hasJobListingToApply =
    !!jobListingId && !!jobListingData && jobListingData?.GetPublicJobListing?.Requisition?.Company?.id === company?.id

  return {
    hasCompanyData: !!subdomain && !!company && company.CompanyInfo?.companyName,
    companyId: company?.id,
    companyName: company?.CompanyInfo?.companyName,
    hasJobListingToApply,
    jobListingId,
    jobListingTitle: jobListingData?.GetPublicJobListing?.externalTitle,
    callbackUrl,
  }
}
